const {
  REACT_APP_AD_CLIENT_ID,
  REACT_APP_AD_TENANT_ID,
  REACT_APP_AD_REDIRECT,
  REACT_APP_API_BASE_URL
} = process.env;

// this is for azure active directory future integration
/*if (!REACT_APP_AD_CLIENT_ID) throw Error('REACT_APP_AD_CLIENT_ID Environment Variable missing!');
if (!REACT_APP_AD_TENANT_ID) throw Error('REACT_APP_AD_TENANT_ID Environment Variable missing!');
if (!REACT_APP_AD_REDIRECT) throw Error('REACT_APP_AD_REDIRECT Environment Variable missing!');*/
if(!REACT_APP_API_BASE_URL) throw Error('REACT_APP_API_BASE_URL Environment Variable missing!');
export const apiSettings = {
  baseUrl: REACT_APP_API_BASE_URL
}
export const mgtConfig = {
  clientId: REACT_APP_AD_CLIENT_ID,
  tenantId: REACT_APP_AD_TENANT_ID,
  redirectUri: REACT_APP_AD_REDIRECT
};