import type {FC} from 'react';
import {useRoutes} from 'react-router-dom';
import {Helmet} from 'react-helmet-async';
import {ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import 'react-quill/dist/quill.snow.css'; // Remove if react-quill is not used
import 'simplebar-react/dist/simplebar.min.css'; // Remove if simplebar is not used
import './locales/i18n';  // Remove if locales are not used
import {RTL} from 'src/App/components/rtl';
import {SplashScreen} from "src/App/components/SplashScreen";
import {Toaster} from 'src/App/components/toaster';
import {SettingsConsumer, SettingsProvider} from 'src/App/context/settings-context';
import {useNprogress} from 'src/App/hooks/use-nprogress';
import {Routes as routes} from 'src/App/Routes';
import {createTheme} from 'src/theme';
import {AuthContext, AuthProvider} from "src/App/context/auth-context";
import {LoadingProvider} from './context/loading-context';
import AuthGuard from "./guards/auth-guard";
import {ApiProvider} from "src/App/context/api-context";
import {GuestGuard} from "src/App/guards/guest-guard";
import {AppDataProvider} from 'src/App/context/appdata-context';

export const App: FC = () => {
  useNprogress();

  const element = useRoutes(routes);
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <AuthProvider>
        <AuthContext.Consumer>
          {(auth) => {
            return (
              <SettingsProvider>
                <SettingsConsumer>
                  {(settings) => {
                    // Prevent theme flicker when restoring custom settings from browser storage
                    if (!settings.isInitialized) {
                      return null;
                    }

                    const theme = createTheme({
                      colorPreset: settings.colorPreset,
                      contrast: settings.contrast,
                      direction: settings.direction,
                      paletteMode: settings.paletteMode,
                      responsiveFontSizes: settings.responsiveFontSizes
                    });

                    // Prevent guards from redirecting
                    const showSlashScreen = !auth.isInitialized;
                    const showLogin = !auth.isSignedIn;
                    return (
                      <ThemeProvider theme={theme}>
                        <Helmet>
                          <meta
                            name="color-scheme"
                            content={settings.paletteMode}
                          />
                          <meta
                            name="theme-color"
                            content={theme.palette.neutral[900]}
                          />
                        </Helmet>
                        <RTL direction={settings.direction}>
                          <CssBaseline />
                          {
                            showSlashScreen
                              ? <SplashScreen />
                              : showLogin
                                ? <GuestGuard>{element}</GuestGuard>
                                : (
                                  <AuthGuard>
                                    <ApiProvider>
                                      <AppDataProvider>
                                        <LoadingProvider>
                                          {element}
                                        </LoadingProvider>
                                      </AppDataProvider>
                                    </ApiProvider>
                                  </AuthGuard>
                                )
                          }
                          <Toaster />
                        </RTL>
                      </ThemeProvider>
                    );
                  }}
                </SettingsConsumer>
              </SettingsProvider>
            )
          }}
        </AuthContext.Consumer>
      </AuthProvider>
    </LocalizationProvider>
  );
};
