import React from 'react';
import {useAuth} from "src/App/hooks/use-auth";
import {useRouter} from "src/App/hooks/use-router";
import {paths} from 'src/App/paths';

const AuthGuard:React.FC<IAuthGuardProps> = (props) => {
  const [checked,setChecked] = React.useState<boolean>(false);
  const {children} = props;
  const {isSignedIn, isInitialized} = useAuth();
  const router = useRouter();
  const check = React.useCallback(() => {
    if(!checked && isInitialized && !isSignedIn){
      const searchParams = new URLSearchParams({returnTo: window.location.href}).toString();
      const href = paths.public.login.index + `?${searchParams}`;
      console.log('not logged in, redirecting to login page');
      router.replace(href);
    } else {
      setChecked(true);
    }
  }, [isSignedIn, isInitialized, router, setChecked]);
  
  React.useEffect(check,[]);

  return (
    <>{children}</>
  )
};

export default AuthGuard;

interface IAuthGuardProps {
  children ? : React.ReactNode
}