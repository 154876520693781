import {useContext} from 'react';
import { ApiContext } from '../context/api-context';

export const useApi = () => {
  const context = useContext(ApiContext);
  if (context === undefined) {
    const e = Error('useApi must be used within ApiProvider!');
    console.group(`API Error`);
    console.dir(e);
    console.groupEnd();
  } else {
    return context;
  }
};
