import type {FC} from 'react';
import {Helmet} from 'react-helmet-async';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {tokens} from '../locales/tokens';

interface SeoProps {
  title?: string;
}

export const Seo: FC<SeoProps> = (props) => {
  const {title} = props;
  const {t} = useTranslation();
  const fullTitle = title
    ? title + ` | ${t(tokens.app.appname)}`
    : 'PWM Portal';

  return (
    <Helmet>
      <title>
        {fullTitle}
      </title>
    </Helmet>
  );
};

Seo.propTypes = {
  title: PropTypes.string
};
