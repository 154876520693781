import 'reflect-metadata'
import {Expose, Transform, Type} from 'class-transformer';
import {BaseEntity} from './BaseEntity';
import {Roles, Scopes} from './Roles';

export class UserAccount extends BaseEntity {
  constructor(){
    super();
    this.partitionKey = 'user';
    this.loginHistory = [];
    // @ts-ignore
    this.accountType = Roles.DEFAULT_ROLE;
    this.scopes = [];
  }

  @Expose()
  @Transform(({value}) => value != null ? value : '')
  firstName: string = '';

  @Expose()
  @Transform(({value}) => value != null ? value : '')
  lastName: string = '';

  @Expose()
  get initials(){return }

  @Expose()
  email: string;

  @Expose()
  @Type(() => Date)
  loginHistory: Date[];

  @Expose()
  scopes: Scopes[];

  @Expose()
  accountType: typeof Roles;

  @Expose()
  login(){
    if(!Array.isArray(this.loginHistory)){
      this.loginHistory = [];
    }
    // only keep last 25 logins
    this.loginHistory = [new Date(), ...this.loginHistory.slice(0, 24)];
  }

  @Expose()
  get userActive(){
    return this.isActive;
  }

  @Expose()
  get fullName(){
    return `${this.firstName} ${this.lastName}`;
  }

  @Expose()
  get isAdmin(){
    // @ts-ignore
    return this.accountType === Roles.ADMIN_ROLE;
  }
}