import React from 'react';

const defaultValue: ILoadingContext = {
  isLoading: false,
  setLoading(loading: boolean){}
}

export interface ILoadingContext {
  isLoading: boolean;
  setLoading(loading: boolean): void;
}

export const LoadingContext = React.createContext<ILoadingContext>(defaultValue);
LoadingContext.displayName = 'LoadingContext';

export const LoadingProvider: React.FC<{ children: any }> =(props) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const value: ILoadingContext = {
    isLoading, setLoading: setIsLoading
  };
  return <LoadingContext.Provider value={value}>{props.children}</LoadingContext.Provider>
}