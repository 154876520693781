class StorageUtil {
  /**
   * Stores an item in localStorage storage.
   * @param key   The key under which the item is stored.
   * @param value The item to store. Must be JSON serializable.
   * @return The item.
   *
   */
  public setItem(key: string, value: any): any {
    const serializedValue = JSON.stringify(value);
    localStorage.setItem(key, serializedValue);
    return value;
  }

  /**
   * Retrieves an item from localStorage storage.
   * @param key The key of the item to retrieve.
   * @return The item, or `null` if not found. Must be JSON deserializable.
   */
  public getItem(key: string): any {
    const serializedValue = localStorage.getItem(key);
    if (serializedValue === null) {
      return null;
    }
    return JSON.parse(serializedValue);
  }

  /**
   * Removes an item from localStorage storage.
   * @param key The key of the item to remove.
   */
  public removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  /**
   * Clears all items from localStorage storage.
   */
  public clear(): void {
    localStorage.clear();
  }
}

export const Storage = new StorageUtil();