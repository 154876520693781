import type {FC, ReactNode} from 'react';
import {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useAuth} from 'src/App/hooks/use-auth';
import {useRouter} from 'src/App/hooks/use-router';
import {useSearchParams} from "src/App/hooks/use-search-params";
import {paths} from 'src/App/paths';

interface GuestGuardProps {
  children?: ReactNode;
}

export const GuestGuard: FC<GuestGuardProps> = (props) => {
  const {children} = props;
  const {isSignedIn} = useAuth();
  const router = useRouter();
  const searchParams = useSearchParams();
  const [checked, setChecked] = useState<boolean>(false);

  const check = useCallback(
    () => {
      const returnTo = searchParams.get('returnTo'); 
      
      if (isSignedIn) {
        // returnTo could be an absolute path
        router.replace(paths.authenticated.home);
      } else {
        setChecked(true);
      }
    },
    [isSignedIn, router, searchParams]
  );

  // Only check on mount, this allows us to redirect the user manually when auth state changes
  useEffect(
    () => {
      check();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  if (!checked) {
    return null;
  }

  // If got here, it means that the redirect did not occur, and that tells us that the user is
  // not authenticated / authorized.

  return <>{children}</>;
};

GuestGuard.propTypes = {
  children: PropTypes.node
};
