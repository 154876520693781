import React, {useCallback, useEffect, useState} from "react";
import axios from 'axios';
import {plainToInstance} from 'class-transformer';
import {useRouter} from "src/App/hooks/use-router";
import {useMounted} from "src/App/hooks/use-mounted";
import {useSearchParams} from "src/App/hooks/use-search-params";
import {
  CheckSessionActiveResponse,
  CreateTokenResponse
} from 'api/functions/authenticationHttp/AuthenticationResponses';
import {UserAccount} from 'api/entities/UserAccount';
import {Roles, Scopes} from 'api/entities/Roles';
import {DataResponseDTO} from 'api/responses/DataResponseDTO';
import {AuthenticationAttempt} from 'api/functions/authenticationHttp/AuthenticationDTOs';
import { paths } from "../paths";
import {apiSettings} from '../config';
import {Storage} from '../utils/storage-util';
import {useToast} from '../hooks/use-toast';
import {getFriendlyErrorMessage} from './api-context';
import {CreateTokenDTO} from '../entities/authentication/CreateTokenDTO';
import {LoginRequestDTO} from '../entities/authentication/LoginRequestDTO';

const AuthenticationRoute = 'authentication';
const TwelveHours = (new Date()).valueOf() + 1000 * 60 * 60 * 12;
const SESSION_ID = 'vas_session_id';
export interface IAccount {
  name: string;
  username: string;
  firstName: string;
  lastName: string;
  mail: string;
  photo?: any;
  role: typeof Roles;
  oid: string;
  iat: number;
  exp: number;
  scopes: Scopes[];
}

export interface IAuthContext {
  account?: IAccount;
  expiresAt?: string;
  issuedAt?: string;
  jwt?: string;
  isSignedIn: boolean;
  isInitialized: boolean;
  logout?: () => Promise<void>;
  reAuthJwt?:() => Promise<void>;
  login?: (data: LoginRequestDTO) => Promise<void>;
  createToken?: (params: CreateTokenDTO) => Promise<any>;
  checkToken?: (token: string) => Promise<any>;
  isAdmin?: () => boolean;
}

export const AuthContext = React.createContext<IAuthContext>({isSignedIn: false, isInitialized: false});
AuthContext.displayName = 'AuthContext';
export const AuthProvider: React.FC<{ children: any; }> = (props) => {
  const [isInitialized, setIsInitialized] = React.useState<boolean>(false);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [jwt, setJwt] = React.useState<string | undefined>();
  const [userAccount, setUserAccount] = React.useState<IAccount | undefined>();
  const [expiresAt, setExpiresAt] = React.useState<string | undefined>();
  const [issuedAt, setIssuedAt] = React.useState<string | undefined>();
  const [checked, setChecked] = useState(false);
  
  const router = useRouter();
  // @ts-ignore
  window.router = router;

  const mounted = useMounted();
  const searchParams = useSearchParams();
  const returnTo = searchParams.get('returnTo');
  const toast = useToast();

  const check = useCallback(
    () => {
      if(mounted() && !isSignedIn && checked){
        const searchParams = new URLSearchParams({returnTo: window.location.href}).toString();
        const href = paths.public.login.index + `?${searchParams}`;
        console.log(`redirecting to login screen ${href}`);
        router.replace(href);
      } else {
        setChecked(true);
      }
    },
    [mounted, isSignedIn, checked, router]
  );

  // Only check on mount, this allows us to redirect the user manually when auth state changes
  useEffect(
    () => {
      check();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getSignedJwt = async (user: IAccount) => {

  }

  const checkSessionActive = async (sessionid: string) => {
    try {
      const res = await axios.get<DataResponseDTO<CheckSessionActiveResponse>>(`${apiSettings.baseUrl}/${AuthenticationRoute}`, {
        params: {stillAlive: true},
        headers: {sessionid}
      });
      const {success, account} = res.data.data;
      console.log(`Checking logged in state response: success = ${success}, account: ${JSON.stringify(account)}`);
      if (!success) {
        if (value && value.logout) {
          console.log('Not logged in, clearing state');
          await value.logout();
        }
      }
      return {success, account};
    } catch (err) {
      return {success: false};
    }
  }
  const checkSession = useCallback(async () => {
    if (window.location.pathname === paths.public.login.checkToken) {
      console.log('checking token, not checking for active session');
      setIsInitialized(true);
      return;
    }
    // check session key
    const sessionId = Storage.getItem(SESSION_ID);
    if (sessionId) {
      console.log(`Found session ${sessionId}`);
      const res = await checkSessionActive(sessionId);
      if (res.success && res.account) {
        const account = plainToInstance(UserAccount, res.account, {
          exposeDefaultValues: true,
          enableImplicitConversion: true
        });
        setJwt(sessionId);
        setUserAccount({
          role: account.accountType,
          name: account.fullName,
          mail: account.email,
          username: account.email,
          firstName: account.firstName,
          lastName: account.lastName,
          oid: account.email,
          exp: TwelveHours,
          iat: (new Date()).valueOf(),
          scopes: account.scopes
        });
        toast.success('You are logged in');
      }
    }
    setIsInitialized(true);
  }, []);

  useEffect(() => {
    checkSession();
  }, []);
  
  useEffect(() => {
    console.log(isInitialized, jwt);
    const iSL = isInitialized && Boolean(jwt);
    console.log(`Setting logged in state ${iSL}`)
    setIsSignedIn(iSL);
  }, [jwt, isInitialized]);

  const value: IAuthContext = {
    isSignedIn,
    account: userAccount,
    isInitialized,
    expiresAt,
    issuedAt,
    jwt,
    async createToken(params: CreateTokenDTO): Promise<any>{
      try {
        const {data: {data}} = await axios.post<DataResponseDTO<CreateTokenResponse>>(`${apiSettings.baseUrl}/${AuthenticationRoute}`, params);
        if (data.redirect) {
          try {
            const url = new URL(`${document.location.origin}${data.redirect}`);
            return router.push(`${url.pathname}${url.search}`);
          } catch (e) {
            console.error(e);
          }
        }
      } catch (err) {
        toast.error(getFriendlyErrorMessage(err));
      }
    },
    async checkToken(token: string){
      const { data } = await axios.get<AuthenticationAttempt>(`${apiSettings.baseUrl}/${AuthenticationRoute}`, {params: {token}});
      if (data.success && data.userAccount) {
        const userAccount = plainToInstance(UserAccount, data.userAccount, {exposeDefaultValues: true, enableImplicitConversion: true});
        Storage.setItem(SESSION_ID, data.token);
        setJwt(data.token);
        setUserAccount(convertToAccount(userAccount));
        if(data.path) {
          setTimeout(() => {
            const url = new URL(data.path);
            console.log(`redirecting to route: ${url.pathname}`);
            router.push(url.pathname);
          }, 2000);
        }
      } else {
        setJwt(undefined);
        setUserAccount(undefined);
      }
    },
    async login(params: CreateTokenDTO, redirect?: string){
      /*
       todo this is for when we go with username and password authentication
      const loginParams = {
        email: params.email,
        path: params.path
      };

      const {data: {data}} = await axios.post<DataResponseDTO<CreateTokenResponse>>(`${apiSettings.baseUrl}/${AuthenticationRoute}`, loginParams);
      console.dir(data);
      const {token, success, account} = data;
      if(success && account){
        setJwt(token);
        setUserAccount(convertToAccount(account));
      }*/
    },
    async logout(){
      // todo start here
      // await axios.delete(`${apiSettings.baseUrl}/${AuthenticationRoute}`, {headers:{sessionid: jwt}});
      setUserAccount(undefined);
      setJwt(undefined);
      Storage.clear();
      return;
    },
    async reAuthJwt (){if(userAccount) return getSignedJwt(userAccount)},
    // @ts-ignore
    isAdmin(){ return userAccount?.role === Roles.ADMIN_ROLE;}
  };

  return (
    <AuthContext.Provider value={value}>
      {props.children}
    </AuthContext.Provider>
  )
};

const convertToAccount = (account: UserAccount) => ({
  role: account.accountType,
  name: account.fullName,
  mail: account.email,
  username: account.email,
  firstName: account.firstName,
  lastName: account.lastName,
  oid: account.email,
  exp: TwelveHours,
  iat: (new Date()).valueOf(),
  scopes: account.scopes
});
