import {lazy, Suspense} from 'react';
import {RouteObject} from 'react-router';
import {Outlet} from 'react-router-dom';
import {PortalLayout} from './layouts/PortalLayout';
import Error404Page from 'src/App/pages/RouteNotFound';
import {paths} from './paths';
import {GuestGuard} from 'src/App/guards/guest-guard';
import AuthGuard from 'src/App/guards/auth-guard';
import {PublicLayout} from './layouts/PublicLayout';

const authenticatedElements = (
  <AuthGuard>
    <PortalLayout>
      <Suspense>
        <Outlet />
      </Suspense>
    </PortalLayout>
  </AuthGuard>
);

// UN-AUTHENTICATED PAGES
// ====================================
const LoginPage = lazy(() => import('./pages/LoginPage'));
const CheckTokenPage = lazy(() => import('./pages/login/CheckTokenScreen'));

// AUTHENTICATED PAGES
// ====================================
// Main Application Pages
const Home = lazy(() => import('src/App/pages/HomePage'));

// Administration Pages
const Jobs = lazy(() => import ('src/App/pages/Jobs/ListPage'));
const JobDetail = lazy(() => import ('src/App/pages/Jobs/Detail'));
const JobHistoryPage = lazy(() => import ('src/App/pages/Jobs/JobHistory'));
const QueueDataList = lazy(() => import ('src/App/pages/Jobs/Queues/ListPage'));
const Users = lazy(() => import('src/App/pages/Users/ListPage'));
const UserDetail = lazy(() => import('src/App/pages/Users/Detail'));
const UserNew = lazy(() => import('src/App/pages/Users/New'));
const RecordHistoryPage = lazy(() => import('src/App/pages/RecordHistory/RecordHistoryPage'));

// OPEN NTP SURVEY PAGES
const SurveyDetail = lazy(() => import('src/App/pages/Surveys/Detail'));
const SurveyListHome = lazy(() => import('src/App/pages/Surveys/ListPage'));

// EXCEPTION VENDOR ACCRUALS PAGES
const ExceptionVendorDetail = lazy(() => import ('src/App/pages/ExceptionAccruals/Detail'));
const ExceptionVendorListHome = lazy(() => import('src/App/pages/ExceptionAccruals/ListPage'));

// PROFESSIONAL SERVICES ACCRUAL PAGES
const ProServWorksheetListHome = lazy(() => import('src/App/pages/ProServAccruals/ListPage'));
const ProServVendorPage = lazy(() => import('src/App/pages/ProServAccruals/VendorDetail/ProServVendorDetailPage'));
const ProServAccrualDetail = lazy(() => import('src/App/pages/ProServAccruals/AccrualDetail'));

export const Routes: RouteObject[] = [
  {
    path: paths.authenticated.index,
    element: authenticatedElements,
    children: [
      {
        path: paths.authenticated.home,
        element: <Home />
      }]
  }, {
    path: paths.authenticated.app.jobs.index,
    element: authenticatedElements,
    children: [{
      path: paths.authenticated.app.jobs.index,
      element: <Jobs />
    }, {
      path: paths.authenticated.app.jobs.detail,
      element: <JobDetail />
    }, {
      path: paths.authenticated.app.jobs.jobLog,
      element: <JobHistoryPage />
    }]
  }, {
    path: paths.authenticated.app.queue.index,
    element: authenticatedElements,
    children: [{
      path: paths.authenticated.app.queue.index,
      element: <QueueDataList />
    }]
  }, {
    path: paths.authenticated.recordHistory.detail,
    element: authenticatedElements,
    children: [{
      path: paths.authenticated.recordHistory.detail,
      element: <RecordHistoryPage/>
    }]
  },
  {
    path: paths.authenticated.app.users.index,
    element: authenticatedElements,
    children: [{
      path: paths.authenticated.app.users.index,
      element: <Users />
    }, {
      path: paths.authenticated.app.users.new,
      element: <UserNew />
    }, {
      path: paths.authenticated.app.users.detail,
      element: <UserDetail />
    }]
  },
  {
    path: paths.authenticated.survey.index,
    element: authenticatedElements,
    children: [{
      path: paths.authenticated.survey.index,
      element: <SurveyListHome />
    }, {
      path: paths.authenticated.survey.detail,
      element: <SurveyDetail />
    }]
  },
  {
    path: paths.authenticated.accruals.index,
    element: authenticatedElements,
    children: [{
      path: paths.authenticated.accruals.index,
      element: <ExceptionVendorListHome />
    }, {
      path: paths.authenticated.accruals.details,
      element: <ExceptionVendorDetail />
    }]
  }, {
    path: paths.authenticated.proserv.index,
    element: authenticatedElements,
    children: [{
      path: paths.authenticated.proserv.index,
      element: <ProServWorksheetListHome/>
    }, {
      path: paths.authenticated.proserv.details,
      element: <ProServVendorPage/>
    },{
      path: paths.authenticated.proserv.accrualRecord,
      element: <ProServAccrualDetail/>
    }]
  },
  {
    path: paths.public.login.index,
    element:
      (
        <GuestGuard>
          <PublicLayout>
            <Suspense>
              <Outlet />
            </Suspense>
          </PublicLayout>
        </GuestGuard>
      ),
    children:
      [{
        index: true,
        element: <LoginPage />
      }, {
        path: paths.public.login.checkToken,
        element: <CheckTokenPage />
      }]
  },
  {
    path: '*',
    element:
      <Error404Page />
  }
];