import React from 'react';
import {AppDataContext} from '../context/appdata-context';

export const useAppData = () => {
  const context = React.useContext(AppDataContext);
  if(context === undefined) {
    const e = Error('AppDataContext must be used within AppDataProvider!');
    console.group('AppData Context Error');
    console.dir(e);
    console.groupEnd();
  } else {
    return context;
  }
}