import React from 'react';
import {AuthContext} from "../context/auth-context";

export const useAuth = () => {
  const context = React.useContext(AuthContext);
  if(context === undefined) {
    throw new Error('useAuth must be used within AuthProvider!');
  } else {
    return context;
  }
}

