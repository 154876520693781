import React from 'react';
import {Avatar, SvgIcon} from "@mui/material";
import User01Icon from "@untitled-ui/icons-react/build/esm/User01";
import {getInitials} from "src/App/utils/get-initials";
import {useAuth} from "src/App/hooks/use-auth";

const UserAvatar: React.FC<IUserAvatarProps> = (props) => {
  const {account} = useAuth();
  return (
    <Avatar
      sx={{height: 32, width: 32}}
      src={account?.photo}
    >
      {account?.name ? getInitials(account?.name) : (
        <SvgIcon fontSize="small">
          <User01Icon/>
        </SvgIcon>)}
    </Avatar>
  )
};

export default UserAvatar;

interface IUserAvatarProps {
  children?: React.ReactNode
}