import {ReactNode, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {Scopes} from 'api/entities/Roles';
import {useAppData} from './hooks/use-app-data';
import {useAuth} from './hooks/use-auth';
import {tokens} from './locales/tokens';
import {paths} from './paths';

export interface AppSideNavItem {
  disabled?: boolean;
  external?: boolean;
  icon?: ReactNode;
  items?: AppSideNavItem[];
  label?: ReactNode;
  path?: string;
  title: string;
}

export interface AppSideNavSection {
  items: AppSideNavItem[];
  subheader?: string;
}

export const useAppNavSections = () => {
  const {t} = useTranslation();
  const appData = useAppData();
  const auth = useAuth();
  const user = auth.account;
  return useMemo((): AppSideNavSection[] => {
    if (!user || !auth) return [];
    // @ts-ignore
    const AppSections: { [prop: Scopes]: AppSideNavItem } = {
      [Scopes.OpenNTPSurvey]: {
        title: t(tokens.nav.openntpsurvey),
        path: paths.authenticated.survey.index
      },
      [Scopes.Accruals]: {
        title: t(tokens.nav.accruals),
        path: paths.authenticated.accruals.index
      },
      [Scopes.ProServAccruals]: {
        title: t(tokens.nav.proserv),
        path: paths.authenticated.proserv.index
      }
    };
    // @ts-ignore if admin show all sections, else only user sections
    let itemsUserHasAccessTo: AppSideNavItem[] = auth?.isAdmin() ? Object.values(AppSections) : user.scopes.map((scope: Scopes) => (AppSections[scope]));
    // @ts-ignore if admin
    if (auth?.isAdmin()) {
      itemsUserHasAccessTo = [{
        title: t(tokens.nav.jobs),
        path: paths.authenticated.app.jobs.index
      },
        {
          title: t(tokens.nav.users),
          path: paths.authenticated.app.users.index
        }, {
          title: t(tokens.nav.queueData),
          path: paths.authenticated.app.queue.index
        },
        ...itemsUserHasAccessTo];
    }
    // configure sections based on user scopes
    const sections: AppSideNavSection[] = [{
      subheader: `${t(tokens.app.appname)} ${appData?.environment}`,
      items: [{
        title: t(tokens.nav.home),
        path: paths.authenticated.home
      },
        ...itemsUserHasAccessTo]
    }];
    return sections;
  }, [t, appData?.environment, auth?.account]);
};