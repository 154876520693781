import {tokens} from '../tokens';

const interfaces = {
  [tokens.interface.viewRecordHistory]: 'View Record History',
  [tokens.interface.sendReminderEmail]: 'Send Reminder Email',
  [tokens.interface.completeAndLock]: 'Complete and Lock',
  [tokens.interface.viewUnSubmittedOnly]: 'View Unsubmitted Records Only',
  [tokens.interface.sentTo]: 'Sent To',
  [tokens.interface.show]: 'Show',
  [tokens.interface.hide]: 'Hide',
  [tokens.interface.save]: 'Save',
  [tokens.interface.submit]: 'Submit',
  [tokens.interface.survey]: 'Survey',
  [tokens.interface.worksheet]: 'Worksheet',
  [tokens.interface.formSubmitted]: 'This form was submitted on'
};
const nav = {
  [tokens.nav.home]: 'Home',
  [tokens.nav.jobs]: 'Jobs',
  [tokens.nav.users]: 'Users',
  [tokens.nav.queueData]: 'Queue Data'
}
const labels = {
  [tokens.labels.amount]: 'Amount',
  [tokens.labels.comments]: 'Comments',
  [tokens.labels.createDate]: 'Created Date',
  [tokens.labels.recordNo]: 'Record Number',
  [tokens.labels.ntpNo]: 'NTP/PO Number',
  [tokens.labels.ntpTitle]: 'NTP/PO Title',
  [tokens.labels.lineNo]: 'Line',
  [tokens.labels.lineComplete]: 'Line Complete',
  [tokens.labels.invoiceNumber]: 'Invoice Number',
  [tokens.labels.invoiceAmount]: 'Invoice Amount',
  [tokens.labels.contractNo]: 'Contract Number',
  [tokens.labels.projectName]: 'Project Name',
  [tokens.labels.projectNumber]: 'Project Number',
  [tokens.labels.periodsToAccrue]: 'Periods to Accrue',
  [tokens.labels.integrationComplete]: 'Unifier Integration Complete',
  [tokens.labels.servicesThroughDate]: 'Services Through Date',
  [tokens.labels.submitted]: 'Submitted',
  [tokens.labels.submittedBy]: 'Submitted By',
  [tokens.labels.submittedOn]: 'Submitted On',
  [tokens.labels.instructions]: 'Instructions',
  [tokens.labels.note]: 'Note',
  [tokens.labels.simultaneousEditing]: 'Simultaneous Editing'
}
const openntpsurveys = {
  [tokens.nav.openntpsurvey]: 'Open NTP Surveys', // todo 2024/25 Change to Worksheet
  [tokens.labels.lastApprovedPaymentPeriod]: 'Last Approved Payment - Period To',
  [tokens.labels.potentialPeriods]: 'Potential Payment Periods to be Accrued Through May'
};
const accruals = {
  [tokens.nav.accruals]: 'Accrual Worksheets', // (exception vendor)
  [tokens.labels.remainingNtp]: 'Remaining NTP Value'
}

const proserv = {
  [tokens.nav.proserv]: 'Professional Services Worksheets'
}

const common = {
  [tokens.common.languageChanged]: 'Language changed',
};

const app = {
  [tokens.app.appname]: 'FPC Vendor Accruals System',
  [tokens.app.login]: 'Login',
}
export const en = {
  ...nav,
  ...common,
  ...app,
  ...openntpsurveys,
  ...accruals,
  ...proserv,
  ...interfaces,
  ...labels
};