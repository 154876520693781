import type { FC } from 'react';
import {useTheme} from "@mui/material/styles";

export const AppLogo: FC = () => {
  const theme = useTheme();
  const fillColor = theme.palette.background.paper;
  return LightMode;
};

const LightMode =
  <svg width="100%" height="100%" viewBox="0 0 1862 429" version="1.1" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" strokeLinejoin="round" strokeMiterlimit={2}>
    <g transform="matrix(4.16667,0,0,4.16667,0,0)">
        <path d="M136.637,46.791C133.079,46.791 129.115,45.805 125.961,44.089L128.304,39.413C131.232,40.872 133.754,41.816 136.907,41.816C140.511,41.816 142.403,40.4 142.403,37.74C142.403,35.811 141.096,34.61 138.304,33.88L134.835,32.98C131.727,32.164 129.791,31.007 128.664,29.205C127.809,27.875 127.358,26.331 127.358,24.658C127.358,19.211 131.953,15.437 138.574,15.437C142.403,15.437 146.276,16.552 149.024,18.439L146.051,22.729C143.213,21.099 141.096,20.455 138.754,20.455C136.187,20.455 134.385,21.87 134.385,23.887C134.385,25.431 135.241,26.202 137.853,26.889L141.637,27.875C146.772,29.205 149.565,32.336 149.565,36.754C149.565,42.802 144.43,46.791 136.637,46.791Z" fill="rgb(125,112,108)" fillRule="nonzero" />
      <path d="M163.628,36.539C159.439,36.539 157.998,37.269 157.998,39.885C157.998,41.601 159.124,42.759 160.655,42.759C161.781,42.759 162.907,42.201 163.808,41.258L163.898,36.539L163.628,36.539ZM168.763,47.263C167.367,46.705 166.105,45.719 165.52,44.604C165.069,45.032 164.574,45.461 164.124,45.762C162.997,46.533 161.375,46.963 159.484,46.963C154.349,46.963 151.556,44.475 151.556,40.1C151.556,34.952 155.294,32.551 162.637,32.551C163.087,32.551 163.493,32.551 163.988,32.594L163.988,31.693C163.988,29.248 163.493,28.433 161.286,28.433C159.349,28.433 157.096,29.334 154.619,30.921L152.051,26.803C153.268,26.074 154.168,25.645 155.79,25.002C158.042,24.101 159.979,23.715 162.096,23.715C165.97,23.715 168.627,25.087 169.529,27.532C169.844,28.433 169.979,29.119 169.934,31.479L169.799,38.856C169.754,41.258 169.934,42.287 171.961,43.745L168.763,47.263Z" fill="rgb(125,112,108)" fillRule="nonzero" />
      <path d="M186.867,46.148L186.867,31.864C186.867,29.377 186.416,28.648 184.839,28.648C183.624,28.648 182.047,29.419 180.65,30.62L180.65,46.148L174.615,46.148L174.615,30.235C174.615,28.347 174.344,26.546 173.804,25.087L179.164,23.629C179.705,24.53 180.02,25.474 180.02,26.374C180.92,25.774 181.687,25.259 182.677,24.744C183.894,24.144 185.47,23.801 186.821,23.801C189.389,23.801 191.641,25.087 192.362,26.975C192.677,27.789 192.812,28.734 192.812,30.106L192.812,46.148L186.867,46.148Z" fill="rgb(125,112,108)" fillRule="nonzero" />
      <path d="M219.883,23.543C218.847,21.913 217.406,21.056 214.928,21.056L212.046,21.056L212.046,41.258L215.424,41.258C219.568,41.258 221.46,38.212 221.46,31.907C221.46,28.433 221.145,25.517 219.883,23.543ZM225.379,41.687C223.487,44.132 220.694,45.762 217.766,45.976C216.595,46.062 215.109,46.148 213.262,46.148L205.65,46.148L205.65,16.337L211.956,16.337C213.757,16.337 216.145,16.423 217.406,16.509C220.064,16.724 222.361,17.667 224.252,19.469C227.135,22.171 228.532,25.989 228.532,31.35C228.532,35.425 227.496,38.985 225.379,41.687Z" fill="rgb(125,112,108)" fillRule="nonzero" />
      <path d="M232.181,46.148L232.181,24.573L238.217,23.672L238.217,46.148L232.181,46.148ZM235.199,21.699C233.172,21.699 231.505,20.112 231.505,18.139C231.505,16.166 233.217,14.578 235.289,14.578C237.316,14.578 238.938,16.166 238.938,18.139C238.938,20.112 237.271,21.699 235.199,21.699Z" fill="rgb(125,112,108)" fillRule="nonzero" />
      <path d="M255.158,32.508C255.158,30.878 254.977,30.02 254.437,29.205C253.851,28.347 252.996,27.918 251.779,27.918C249.482,27.918 248.175,29.634 248.175,32.68L248.175,32.765L255.158,32.765L255.158,32.508ZM248.085,36.969L248.085,37.14C248.085,40.529 249.842,42.459 252.951,42.459C255.022,42.459 256.959,41.73 258.806,40.272L261.148,43.702C258.491,45.762 255.698,46.748 252.455,46.748C245.833,46.748 241.554,42.287 241.554,35.382C241.554,31.436 242.41,28.82 244.437,26.674C246.329,24.658 248.626,23.715 251.689,23.715C254.346,23.715 256.87,24.573 258.355,26.031C260.472,28.09 261.418,31.049 261.418,35.639L261.418,36.969L248.085,36.969Z" fill="rgb(125,112,108)" fillRule="nonzero" />
      <path d="M273.13,28.004C270.833,28.004 269.527,29.205 269.527,31.264C269.527,33.494 270.968,34.395 273.085,34.395C275.473,34.395 276.78,33.28 276.78,31.264C276.78,29.205 275.428,28.004 273.13,28.004ZM283.22,28.219C282.635,28.219 282.004,28.133 281.644,28.09C282.545,29.119 282.996,30.235 282.996,31.693C282.996,35.467 279.392,38.212 274.482,38.212C274.211,38.212 274.032,38.212 273.582,38.17C272.049,38.856 271.194,39.37 271.194,39.971C271.194,40.272 271.554,40.486 272.185,40.486L275.428,40.529C278.942,40.572 280.789,41.086 282.41,42.502C283.761,43.702 284.392,45.204 284.392,47.177C284.392,49.022 283.806,50.437 282.545,51.724C280.518,53.782 277.049,54.555 273.446,54.555C270.158,54.555 266.78,54.04 264.708,52.196C263.446,51.08 262.816,49.793 262.816,48.292C262.816,47.091 263.131,46.49 263.356,46.062L268.942,46.062C268.716,46.576 268.671,46.877 268.671,47.478C268.671,49.236 270.203,50.18 272.996,50.18C274.527,50.18 275.833,50.008 276.78,49.45C277.68,48.936 278.266,48.12 278.266,47.22C278.266,45.247 276.418,44.647 274.032,44.604L271.464,44.561C268.761,44.518 267.004,44.303 265.923,43.874C264.842,43.489 264.167,42.502 264.167,41C264.167,39.585 264.618,38.255 268.401,37.312C265.068,36.497 263.446,34.481 263.446,31.221C263.446,26.717 267.275,23.801 273.176,23.801C274.482,23.801 275.653,23.972 277.32,24.359C278.581,24.658 279.392,24.83 280.158,24.83C281.825,24.83 283.536,24.144 284.932,22.943L287.455,26.589C286.149,27.747 284.887,28.219 283.22,28.219Z" fill="rgb(125,112,108)" fillRule="nonzero" />
      <path d="M300.054,29.162C299.468,28.433 298.522,28.004 297.441,28.004C296,28.004 294.829,28.862 294.333,30.235C293.928,31.393 293.703,32.937 293.703,35.124C293.703,37.655 293.973,39.542 294.468,40.657C295.009,41.859 296.36,42.459 297.532,42.459C300.144,42.459 301.27,40.229 301.27,35.038C301.27,32.079 300.865,30.149 300.054,29.162ZM304.963,43.574C303.207,45.461 300.91,46.619 297.351,46.619C291.09,46.619 286.991,42.158 286.991,35.253C286.991,28.347 291.135,23.758 297.351,23.758C300.639,23.758 303.207,24.83 305.189,27.061C307.036,29.119 307.846,31.522 307.846,35.081C307.846,38.856 306.946,41.43 304.963,43.574Z" fill="rgb(125,112,108)" fillRule="nonzero" />
      <path d="M341.494,41.086C340.682,43.446 337.62,46.705 330.863,46.705C326.088,46.705 322.575,45.332 320.818,42.716C319.782,41.172 319.467,39.971 319.467,37.097L319.467,16.337L325.908,16.337L325.908,35.94C325.908,37.783 326.088,38.856 326.449,39.499C327.169,40.829 328.656,41.558 330.728,41.558C333.475,41.558 335.232,40.272 335.502,38.084C335.592,37.441 335.592,37.012 335.592,35.425L335.592,16.337L341.989,16.337L341.989,36.411C341.989,39.242 341.944,39.8 341.494,41.086Z" fill="rgb(125,112,108)" fillRule="nonzero" />
      <path d="M359.07,46.148L359.07,31.864C359.07,29.377 358.62,28.648 357.042,28.648C355.827,28.648 354.25,29.419 352.854,30.62L352.854,46.148L346.818,46.148L346.818,30.235C346.818,28.347 346.547,26.546 346.006,25.087L351.367,23.629C351.908,24.53 352.223,25.474 352.223,26.374C353.124,25.774 353.889,25.259 354.88,24.744C356.096,24.144 357.673,23.801 359.025,23.801C361.592,23.801 363.844,25.087 364.565,26.975C364.88,27.789 365.015,28.734 365.015,30.106L365.015,46.148L359.07,46.148Z" fill="rgb(125,112,108)" fillRule="nonzero" />
      <path d="M369.66,46.148L369.66,24.573L375.696,23.672L375.696,46.148L369.66,46.148ZM372.677,21.699C370.651,21.699 368.984,20.112 368.984,18.139C368.984,16.166 370.696,14.578 372.767,14.578C374.794,14.578 376.417,16.166 376.417,18.139C376.417,20.112 374.75,21.699 372.677,21.699Z" fill="rgb(125,112,108)" fillRule="nonzero" />
      <path d="M391.519,19.297C390.212,18.697 389.762,18.568 389.042,18.568C387.51,18.568 386.519,19.469 386.519,21.056L386.519,24.187L392.375,24.187L390.843,28.133L386.564,28.133L386.564,46.148L380.664,46.148L380.664,28.133L378.591,28.133L378.591,24.187L380.798,24.187C380.664,23.286 380.573,22.171 380.573,21.184C380.573,16.852 383.276,14.364 388.005,14.364C389.988,14.364 391.609,14.75 393.456,15.737L391.519,19.297Z" fill="rgb(125,112,108)" fillRule="nonzero" />
      <path d="M394.586,46.148L394.586,24.573L400.622,23.672L400.622,46.148L394.586,46.148ZM397.604,21.699C395.577,21.699 393.911,20.112 393.911,18.139C393.911,16.166 395.623,14.578 397.694,14.578C399.721,14.578 401.342,16.166 401.342,18.139C401.342,20.112 399.676,21.699 397.604,21.699Z" fill="rgb(125,112,108)" fillRule="nonzero" />
      <path d="M417.563,32.508C417.563,30.878 417.382,30.02 416.842,29.205C416.256,28.347 415.401,27.918 414.184,27.918C411.887,27.918 410.581,29.634 410.581,32.68L410.581,32.765L417.563,32.765L417.563,32.508ZM410.491,36.969L410.491,37.14C410.491,40.529 412.248,42.459 415.356,42.459C417.427,42.459 419.365,41.73 421.211,40.272L423.553,43.702C420.896,45.762 418.103,46.748 414.86,46.748C408.239,46.748 403.959,42.287 403.959,35.382C403.959,31.436 404.815,28.82 406.842,26.674C408.734,24.658 411.032,23.715 414.094,23.715C416.752,23.715 419.274,24.573 420.761,26.031C422.877,28.09 423.824,31.049 423.824,35.639L423.824,36.969L410.491,36.969Z" fill="rgb(125,112,108)" fillRule="nonzero" />
      <path d="M439.643,29.977C438.517,29.033 437.436,28.562 436.355,28.562C433.653,28.562 432.482,30.706 432.482,35.596C432.482,40.315 433.518,41.859 436.67,41.859C437.796,41.859 439.058,41.129 439.643,40.443L439.643,29.977ZM440.995,46.148C440.815,45.805 440.725,45.461 440.634,44.861C439.148,46.105 437.391,46.705 435.319,46.705C429.463,46.705 425.77,42.373 425.77,35.553C425.77,28.691 429.779,23.972 435.634,23.972C437.301,23.972 438.608,24.359 439.734,25.216C439.643,24.744 439.553,23.243 439.553,21.956L439.553,14.407L445.544,15.308L445.544,38.127C445.544,43.531 445.995,45.29 446.4,46.148L440.995,46.148Z" fill="rgb(125,112,108)" fillRule="nonzero" />
      <path d="M127.528,73.712L129.479,71.374C130.832,72.489 132.248,73.198 133.964,73.198C135.315,73.198 136.131,72.661 136.131,71.781L136.131,71.739C136.131,70.902 135.616,70.473 133.106,69.829C130.08,69.057 128.128,68.22 128.128,65.239L128.128,65.196C128.128,62.471 130.317,60.668 133.385,60.668C135.573,60.668 137.439,61.355 138.962,62.577L137.246,65.067C135.916,64.144 134.607,63.586 133.342,63.586C132.076,63.586 131.411,64.165 131.411,64.895L131.411,64.938C131.411,65.925 132.055,66.246 134.65,66.911C137.697,67.705 139.413,68.799 139.413,71.417L139.413,71.459C139.413,74.442 137.139,76.115 133.899,76.115C131.625,76.115 129.33,75.322 127.528,73.712Z" fill="rgb(125,112,108)" fillRule="nonzero" />
      <path d="M144.13,68.435L144.13,68.392C144.13,64.122 147.348,60.625 151.961,60.625C154.793,60.625 156.488,61.57 157.882,62.943L155.78,65.367C154.621,64.316 153.441,63.672 151.939,63.672C149.408,63.672 147.584,65.775 147.584,68.349L147.584,68.392C147.584,70.966 149.365,73.112 151.939,73.112C153.656,73.112 154.707,72.425 155.887,71.353L157.989,73.477C156.445,75.128 154.729,76.158 151.832,76.158C147.413,76.158 144.13,72.747 144.13,68.435Z" fill="rgb(125,112,108)" fillRule="nonzero" />
      <path d="M163.158,60.883L166.462,60.883L166.462,66.825L172.555,66.825L172.555,60.883L175.859,60.883L175.859,75.901L172.555,75.901L172.555,69.872L166.462,69.872L166.462,75.901L163.158,75.901L163.158,60.883Z" fill="rgb(125,112,108)" fillRule="nonzero" />
      <path d="M194.042,68.435L194.042,68.392C194.042,65.818 192.154,63.672 189.494,63.672C186.833,63.672 184.988,65.775 184.988,68.349L184.988,68.392C184.988,70.966 186.876,73.112 189.536,73.112C192.197,73.112 194.042,71.009 194.042,68.435ZM181.534,68.435L181.534,68.392C181.534,64.122 184.902,60.625 189.536,60.625C194.17,60.625 197.496,64.079 197.496,68.349L197.496,68.392C197.496,72.661 194.127,76.158 189.494,76.158C184.859,76.158 181.534,72.704 181.534,68.435Z" fill="rgb(125,112,108)" fillRule="nonzero" />
      <path d="M214.882,68.435L214.882,68.392C214.882,65.818 212.995,63.672 210.334,63.672C207.674,63.672 205.829,65.775 205.829,68.349L205.829,68.392C205.829,70.966 207.717,73.112 210.377,73.112C213.038,73.112 214.882,71.009 214.882,68.435ZM202.375,68.435L202.375,68.392C202.375,64.122 205.743,60.625 210.377,60.625C215.011,60.625 218.336,64.079 218.336,68.349L218.336,68.392C218.336,72.661 214.968,76.158 210.334,76.158C205.7,76.158 202.375,72.704 202.375,68.435Z" fill="rgb(125,112,108)" fillRule="nonzero" />
      <path d="M223.977,60.883L227.282,60.883L227.282,72.897L234.769,72.897L234.769,75.901L223.977,75.901L223.977,60.883Z" fill="rgb(125,112,108)" fillRule="nonzero" />
      <path d="M254.909,72.918C257.612,72.918 259.435,71.095 259.435,68.435L259.435,68.392C259.435,65.732 257.612,63.865 254.909,63.865L252.356,63.865L252.356,72.918L254.909,72.918ZM249.051,60.883L254.909,60.883C259.627,60.883 262.889,64.122 262.889,68.349L262.889,68.392C262.889,72.618 259.627,75.901 254.909,75.901L249.051,75.901L249.051,60.883Z" fill="rgb(125,112,108)" fillRule="nonzero" />
      <rect x="268.639" y="60.883" width="3.304" height="15.018" fill="rgb(125,112,108)" fillRule="nonzero" />
      <path d="M277.332,73.712L279.284,71.374C280.635,72.489 282.051,73.198 283.768,73.198C285.12,73.198 285.934,72.661 285.934,71.781L285.934,71.739C285.934,70.902 285.419,70.473 282.91,69.829C279.884,69.057 277.932,68.22 277.932,65.239L277.932,65.196C277.932,62.471 280.121,60.668 283.188,60.668C285.376,60.668 287.243,61.355 288.766,62.577L287.05,65.067C285.72,64.144 284.412,63.586 283.145,63.586C281.879,63.586 281.214,64.165 281.214,64.895L281.214,64.938C281.214,65.925 281.858,66.246 284.454,66.911C287.5,67.705 289.217,68.799 289.217,71.417L289.217,71.459C289.217,74.442 286.943,76.115 283.704,76.115C281.429,76.115 279.133,75.322 277.332,73.712Z" fill="rgb(125,112,108)" fillRule="nonzero" />
      <path d="M298.192,63.929L293.623,63.929L293.623,60.883L306.066,60.883L306.066,63.929L301.496,63.929L301.496,75.901L298.192,75.901L298.192,63.929Z" fill="rgb(125,112,108)" fillRule="nonzero" />
      <path d="M317.712,68.177C319.321,68.177 320.244,67.319 320.244,66.053L320.244,66.01C320.244,64.594 319.256,63.865 317.648,63.865L314.366,63.865L314.366,68.177L317.712,68.177ZM311.061,60.883L317.926,60.883C319.836,60.883 321.316,61.419 322.303,62.406C323.14,63.242 323.59,64.423 323.59,65.839L323.59,65.882C323.59,68.306 322.282,69.829 320.373,70.537L324.041,75.901L320.179,75.901L316.961,71.095L314.366,71.095L314.366,75.901L311.061,75.901L311.061,60.883Z" fill="rgb(125,112,108)" fillRule="nonzero" />
      <rect x="329.437" y="60.883" width="3.304" height="15.018" fill="rgb(125,112,108)" fillRule="nonzero" />
      <path d="M338.448,68.435L338.448,68.392C338.448,64.122 341.667,60.625 346.279,60.625C349.111,60.625 350.805,61.57 352.2,62.943L350.097,65.367C348.939,64.316 347.759,63.672 346.257,63.672C343.726,63.672 341.902,65.775 341.902,68.349L341.902,68.392C341.902,70.966 343.683,73.112 346.257,73.112C347.973,73.112 349.025,72.425 350.205,71.353L352.307,73.477C350.762,75.128 349.046,76.158 346.15,76.158C341.731,76.158 338.448,72.747 338.448,68.435Z" fill="rgb(125,112,108)" fillRule="nonzero" />
      <path d="M361.342,63.929L356.773,63.929L356.773,60.883L369.216,60.883L369.216,63.929L364.646,63.929L364.646,75.901L361.342,75.901L361.342,63.929Z" fill="rgb(125,112,108)" fillRule="nonzero" />
      <path d="M69.705,103L53.798,89.208L38.021,103L38.021,75.146L53.798,61.347L69.705,75.146L69.705,103Z" fill="rgb(251,176,64)" fillRule="nonzero" />
      <path d="M107.636,52.328L89.602,63.196L97.845,82.463L71.353,73.856L63.103,54.585L81.144,43.72L107.636,52.328Z" fill="rgb(165,38,113)" fillRule="nonzero" />
      <path d="M71.164,0.595L75.925,21.105L96.797,19.219L80.424,41.756L59.548,43.646L54.792,23.131L71.164,0.595Z" fill="rgb(144,176,62)" fillRule="nonzero" />
      <path d="M10.692,19.296L31.67,21.105L36.326,0.672L52.7,23.208L48.046,43.646L27.065,41.832L10.692,19.296Z" fill="rgb(227,75,38)" fillRule="nonzero" />
      <path d="M9.791,82.587L17.993,63.196L0,52.453L26.492,43.845L44.493,54.587L36.284,73.98L9.791,82.587Z" fill="rgb(0,90,155)" fillRule="nonzero" />
    </g>
</svg>;

const DarkMode =
  <svg width="100%" height="100%" viewBox="0 0 1862 429" version="1.1" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" strokeLinejoin="round" strokeMiterlimit={2}>
    <g transform="matrix(4.16667,0,0,4.16667,0,0)">
        <path d="M136.637,46.791C133.079,46.791 129.115,45.805 125.961,44.089L128.304,39.413C131.232,40.872 133.754,41.816 136.907,41.816C140.511,41.816 142.403,40.4 142.403,37.74C142.403,35.811 141.096,34.61 138.304,33.88L134.835,32.98C131.727,32.164 129.791,31.007 128.664,29.205C127.809,27.875 127.358,26.331 127.358,24.658C127.358,19.211 131.953,15.437 138.574,15.437C142.403,15.437 146.276,16.552 149.024,18.439L146.051,22.729C143.213,21.099 141.096,20.455 138.754,20.455C136.187,20.455 134.385,21.87 134.385,23.887C134.385,25.431 135.241,26.202 137.853,26.889L141.637,27.875C146.772,29.205 149.565,32.336 149.565,36.754C149.565,42.802 144.43,46.791 136.637,46.791Z" fill="rgb(206,201,197)" fillRule="nonzero" />
      <path d="M163.628,36.539C159.439,36.539 157.998,37.269 157.998,39.885C157.998,41.601 159.124,42.759 160.655,42.759C161.781,42.759 162.907,42.201 163.808,41.258L163.898,36.539L163.628,36.539ZM168.763,47.263C167.367,46.705 166.105,45.719 165.52,44.604C165.069,45.032 164.574,45.461 164.124,45.762C162.997,46.533 161.375,46.963 159.484,46.963C154.349,46.963 151.556,44.475 151.556,40.1C151.556,34.952 155.294,32.551 162.637,32.551C163.087,32.551 163.493,32.551 163.988,32.594L163.988,31.693C163.988,29.248 163.493,28.433 161.286,28.433C159.349,28.433 157.096,29.334 154.619,30.921L152.051,26.803C153.268,26.074 154.168,25.645 155.79,25.002C158.042,24.101 159.979,23.715 162.096,23.715C165.97,23.715 168.627,25.087 169.529,27.532C169.844,28.433 169.979,29.119 169.934,31.479L169.799,38.856C169.754,41.258 169.934,42.287 171.961,43.745L168.763,47.263Z" fill="rgb(206,201,197)" fillRule="nonzero" />
      <path d="M186.867,46.148L186.867,31.864C186.867,29.377 186.416,28.648 184.839,28.648C183.624,28.648 182.047,29.419 180.65,30.62L180.65,46.148L174.615,46.148L174.615,30.235C174.615,28.347 174.344,26.546 173.804,25.087L179.164,23.629C179.705,24.53 180.02,25.474 180.02,26.374C180.92,25.774 181.687,25.259 182.677,24.744C183.894,24.144 185.47,23.801 186.821,23.801C189.389,23.801 191.641,25.087 192.362,26.975C192.677,27.789 192.812,28.734 192.812,30.106L192.812,46.148L186.867,46.148Z" fill="rgb(206,201,197)" fillRule="nonzero" />
      <path d="M219.883,23.543C218.847,21.913 217.406,21.056 214.928,21.056L212.046,21.056L212.046,41.258L215.424,41.258C219.568,41.258 221.46,38.212 221.46,31.907C221.46,28.433 221.145,25.517 219.883,23.543ZM225.379,41.687C223.487,44.132 220.694,45.762 217.766,45.976C216.595,46.062 215.109,46.148 213.262,46.148L205.65,46.148L205.65,16.337L211.956,16.337C213.757,16.337 216.145,16.423 217.406,16.509C220.064,16.724 222.361,17.667 224.252,19.469C227.135,22.171 228.532,25.989 228.532,31.35C228.532,35.425 227.496,38.985 225.379,41.687Z" fill="rgb(206,201,197)" fillRule="nonzero" />
      <path d="M232.181,46.148L232.181,24.573L238.217,23.672L238.217,46.148L232.181,46.148ZM235.199,21.699C233.172,21.699 231.505,20.112 231.505,18.139C231.505,16.166 233.217,14.578 235.289,14.578C237.316,14.578 238.938,16.166 238.938,18.139C238.938,20.112 237.271,21.699 235.199,21.699Z" fill="rgb(206,201,197)" fillRule="nonzero" />
      <path d="M255.158,32.508C255.158,30.878 254.977,30.02 254.437,29.205C253.851,28.347 252.996,27.918 251.779,27.918C249.482,27.918 248.175,29.634 248.175,32.68L248.175,32.765L255.158,32.765L255.158,32.508ZM248.085,36.969L248.085,37.14C248.085,40.529 249.842,42.459 252.951,42.459C255.022,42.459 256.959,41.73 258.806,40.272L261.148,43.702C258.491,45.762 255.698,46.748 252.455,46.748C245.833,46.748 241.554,42.287 241.554,35.382C241.554,31.436 242.41,28.82 244.437,26.674C246.329,24.658 248.626,23.715 251.689,23.715C254.346,23.715 256.87,24.573 258.355,26.031C260.472,28.09 261.418,31.049 261.418,35.639L261.418,36.969L248.085,36.969Z" fill="rgb(206,201,197)" fillRule="nonzero" />
      <path d="M273.13,28.004C270.833,28.004 269.527,29.205 269.527,31.264C269.527,33.494 270.968,34.395 273.085,34.395C275.473,34.395 276.78,33.28 276.78,31.264C276.78,29.205 275.428,28.004 273.13,28.004ZM283.22,28.219C282.635,28.219 282.004,28.133 281.644,28.09C282.545,29.119 282.996,30.235 282.996,31.693C282.996,35.467 279.392,38.212 274.482,38.212C274.211,38.212 274.032,38.212 273.582,38.17C272.049,38.856 271.194,39.37 271.194,39.971C271.194,40.272 271.554,40.486 272.185,40.486L275.428,40.529C278.942,40.572 280.789,41.086 282.41,42.502C283.761,43.702 284.392,45.204 284.392,47.177C284.392,49.022 283.806,50.437 282.545,51.724C280.518,53.782 277.049,54.555 273.446,54.555C270.158,54.555 266.78,54.04 264.708,52.196C263.446,51.08 262.816,49.793 262.816,48.292C262.816,47.091 263.131,46.49 263.356,46.062L268.942,46.062C268.716,46.576 268.671,46.877 268.671,47.478C268.671,49.236 270.203,50.18 272.996,50.18C274.527,50.18 275.833,50.008 276.78,49.45C277.68,48.936 278.266,48.12 278.266,47.22C278.266,45.247 276.418,44.647 274.032,44.604L271.464,44.561C268.761,44.518 267.004,44.303 265.923,43.874C264.842,43.489 264.167,42.502 264.167,41C264.167,39.585 264.618,38.255 268.401,37.312C265.068,36.497 263.446,34.481 263.446,31.221C263.446,26.717 267.275,23.801 273.176,23.801C274.482,23.801 275.653,23.972 277.32,24.359C278.581,24.658 279.392,24.83 280.158,24.83C281.825,24.83 283.536,24.144 284.932,22.943L287.455,26.589C286.149,27.747 284.887,28.219 283.22,28.219Z" fill="rgb(206,201,197)" fillRule="nonzero" />
      <path d="M300.054,29.162C299.468,28.433 298.522,28.004 297.441,28.004C296,28.004 294.829,28.862 294.333,30.235C293.928,31.393 293.703,32.937 293.703,35.124C293.703,37.655 293.973,39.542 294.468,40.657C295.009,41.859 296.36,42.459 297.532,42.459C300.144,42.459 301.27,40.229 301.27,35.038C301.27,32.079 300.865,30.149 300.054,29.162ZM304.963,43.574C303.207,45.461 300.91,46.619 297.351,46.619C291.09,46.619 286.991,42.158 286.991,35.253C286.991,28.347 291.135,23.758 297.351,23.758C300.639,23.758 303.207,24.83 305.189,27.061C307.036,29.119 307.846,31.522 307.846,35.081C307.846,38.856 306.946,41.43 304.963,43.574Z" fill="rgb(206,201,197)" fillRule="nonzero" />
      <path d="M341.494,41.086C340.682,43.446 337.62,46.705 330.863,46.705C326.088,46.705 322.575,45.332 320.818,42.716C319.782,41.172 319.467,39.971 319.467,37.097L319.467,16.337L325.908,16.337L325.908,35.94C325.908,37.783 326.088,38.856 326.449,39.499C327.169,40.829 328.656,41.558 330.728,41.558C333.475,41.558 335.232,40.272 335.502,38.084C335.592,37.441 335.592,37.012 335.592,35.425L335.592,16.337L341.989,16.337L341.989,36.411C341.989,39.242 341.944,39.8 341.494,41.086Z" fill="rgb(206,201,197)" fillRule="nonzero" />
      <path d="M359.07,46.148L359.07,31.864C359.07,29.377 358.62,28.648 357.042,28.648C355.827,28.648 354.25,29.419 352.854,30.62L352.854,46.148L346.818,46.148L346.818,30.235C346.818,28.347 346.547,26.546 346.006,25.087L351.367,23.629C351.908,24.53 352.223,25.474 352.223,26.374C353.124,25.774 353.889,25.259 354.88,24.744C356.096,24.144 357.673,23.801 359.025,23.801C361.592,23.801 363.844,25.087 364.565,26.975C364.88,27.789 365.015,28.734 365.015,30.106L365.015,46.148L359.07,46.148Z" fill="rgb(206,201,197)" fillRule="nonzero" />
      <path d="M369.66,46.148L369.66,24.573L375.696,23.672L375.696,46.148L369.66,46.148ZM372.677,21.699C370.651,21.699 368.984,20.112 368.984,18.139C368.984,16.166 370.696,14.578 372.767,14.578C374.794,14.578 376.417,16.166 376.417,18.139C376.417,20.112 374.75,21.699 372.677,21.699Z" fill="rgb(206,201,197)" fillRule="nonzero" />
      <path d="M391.519,19.297C390.212,18.697 389.762,18.568 389.042,18.568C387.51,18.568 386.519,19.469 386.519,21.056L386.519,24.187L392.375,24.187L390.843,28.133L386.564,28.133L386.564,46.148L380.664,46.148L380.664,28.133L378.591,28.133L378.591,24.187L380.798,24.187C380.664,23.286 380.573,22.171 380.573,21.184C380.573,16.852 383.276,14.364 388.005,14.364C389.988,14.364 391.609,14.75 393.456,15.737L391.519,19.297Z" fill="rgb(206,201,197)" fillRule="nonzero" />
      <path d="M394.586,46.148L394.586,24.573L400.622,23.672L400.622,46.148L394.586,46.148ZM397.604,21.699C395.577,21.699 393.911,20.112 393.911,18.139C393.911,16.166 395.623,14.578 397.694,14.578C399.721,14.578 401.342,16.166 401.342,18.139C401.342,20.112 399.676,21.699 397.604,21.699Z" fill="rgb(206,201,197)" fillRule="nonzero" />
      <path d="M417.563,32.508C417.563,30.878 417.382,30.02 416.842,29.205C416.256,28.347 415.401,27.918 414.184,27.918C411.887,27.918 410.581,29.634 410.581,32.68L410.581,32.765L417.563,32.765L417.563,32.508ZM410.491,36.969L410.491,37.14C410.491,40.529 412.248,42.459 415.356,42.459C417.427,42.459 419.365,41.73 421.211,40.272L423.553,43.702C420.896,45.762 418.103,46.748 414.86,46.748C408.239,46.748 403.959,42.287 403.959,35.382C403.959,31.436 404.815,28.82 406.842,26.674C408.734,24.658 411.032,23.715 414.094,23.715C416.752,23.715 419.274,24.573 420.761,26.031C422.877,28.09 423.824,31.049 423.824,35.639L423.824,36.969L410.491,36.969Z" fill="rgb(206,201,197)" fillRule="nonzero" />
      <path d="M439.643,29.977C438.517,29.033 437.436,28.562 436.355,28.562C433.653,28.562 432.482,30.706 432.482,35.596C432.482,40.315 433.518,41.859 436.67,41.859C437.796,41.859 439.058,41.129 439.643,40.443L439.643,29.977ZM440.995,46.148C440.815,45.805 440.725,45.461 440.634,44.861C439.148,46.105 437.391,46.705 435.319,46.705C429.463,46.705 425.77,42.373 425.77,35.553C425.77,28.691 429.779,23.972 435.634,23.972C437.301,23.972 438.608,24.359 439.734,25.216C439.643,24.744 439.553,23.243 439.553,21.956L439.553,14.407L445.544,15.308L445.544,38.127C445.544,43.531 445.995,45.29 446.4,46.148L440.995,46.148Z" fill="rgb(206,201,197)" fillRule="nonzero" />
      <path d="M127.528,73.712L129.479,71.374C130.832,72.489 132.248,73.198 133.964,73.198C135.315,73.198 136.131,72.661 136.131,71.781L136.131,71.739C136.131,70.902 135.616,70.473 133.106,69.829C130.08,69.057 128.128,68.22 128.128,65.239L128.128,65.196C128.128,62.471 130.317,60.668 133.385,60.668C135.573,60.668 137.439,61.355 138.962,62.577L137.246,65.067C135.916,64.144 134.607,63.586 133.342,63.586C132.076,63.586 131.411,64.165 131.411,64.895L131.411,64.938C131.411,65.925 132.055,66.246 134.65,66.911C137.697,67.705 139.413,68.799 139.413,71.417L139.413,71.459C139.413,74.442 137.139,76.115 133.899,76.115C131.625,76.115 129.33,75.322 127.528,73.712Z" fill="rgb(206,201,197)" fillRule="nonzero" />
      <path d="M144.13,68.435L144.13,68.392C144.13,64.122 147.348,60.625 151.961,60.625C154.793,60.625 156.488,61.57 157.882,62.943L155.78,65.367C154.621,64.316 153.441,63.672 151.939,63.672C149.408,63.672 147.584,65.775 147.584,68.349L147.584,68.392C147.584,70.966 149.365,73.112 151.939,73.112C153.656,73.112 154.707,72.425 155.887,71.353L157.989,73.477C156.445,75.128 154.729,76.158 151.832,76.158C147.413,76.158 144.13,72.747 144.13,68.435Z" fill="rgb(206,201,197)" fillRule="nonzero" />
      <path d="M163.158,60.883L166.462,60.883L166.462,66.825L172.555,66.825L172.555,60.883L175.859,60.883L175.859,75.901L172.555,75.901L172.555,69.872L166.462,69.872L166.462,75.901L163.158,75.901L163.158,60.883Z" fill="rgb(206,201,197)" fillRule="nonzero" />
      <path d="M194.042,68.435L194.042,68.392C194.042,65.818 192.154,63.672 189.494,63.672C186.833,63.672 184.988,65.775 184.988,68.349L184.988,68.392C184.988,70.966 186.876,73.112 189.536,73.112C192.197,73.112 194.042,71.009 194.042,68.435ZM181.534,68.435L181.534,68.392C181.534,64.122 184.902,60.625 189.536,60.625C194.17,60.625 197.496,64.079 197.496,68.349L197.496,68.392C197.496,72.661 194.127,76.158 189.494,76.158C184.859,76.158 181.534,72.704 181.534,68.435Z" fill="rgb(206,201,197)" fillRule="nonzero" />
      <path d="M214.882,68.435L214.882,68.392C214.882,65.818 212.995,63.672 210.334,63.672C207.674,63.672 205.829,65.775 205.829,68.349L205.829,68.392C205.829,70.966 207.717,73.112 210.377,73.112C213.038,73.112 214.882,71.009 214.882,68.435ZM202.375,68.435L202.375,68.392C202.375,64.122 205.743,60.625 210.377,60.625C215.011,60.625 218.336,64.079 218.336,68.349L218.336,68.392C218.336,72.661 214.968,76.158 210.334,76.158C205.7,76.158 202.375,72.704 202.375,68.435Z" fill="rgb(206,201,197)" fillRule="nonzero" />
      <path d="M223.977,60.883L227.282,60.883L227.282,72.897L234.769,72.897L234.769,75.901L223.977,75.901L223.977,60.883Z" fill="rgb(206,201,197)" fillRule="nonzero" />
      <path d="M254.909,72.918C257.612,72.918 259.435,71.095 259.435,68.435L259.435,68.392C259.435,65.732 257.612,63.865 254.909,63.865L252.356,63.865L252.356,72.918L254.909,72.918ZM249.051,60.883L254.909,60.883C259.627,60.883 262.889,64.122 262.889,68.349L262.889,68.392C262.889,72.618 259.627,75.901 254.909,75.901L249.051,75.901L249.051,60.883Z" fill="rgb(206,201,197)" fillRule="nonzero" />
      <rect x="268.639" y="60.883" width="3.304" height="15.018" fill="rgb(206,201,197)" fillRule="nonzero" />
      <path d="M277.332,73.712L279.284,71.374C280.635,72.489 282.051,73.198 283.768,73.198C285.12,73.198 285.934,72.661 285.934,71.781L285.934,71.739C285.934,70.902 285.419,70.473 282.91,69.829C279.884,69.057 277.932,68.22 277.932,65.239L277.932,65.196C277.932,62.471 280.121,60.668 283.188,60.668C285.376,60.668 287.243,61.355 288.766,62.577L287.05,65.067C285.72,64.144 284.412,63.586 283.145,63.586C281.879,63.586 281.214,64.165 281.214,64.895L281.214,64.938C281.214,65.925 281.858,66.246 284.454,66.911C287.5,67.705 289.217,68.799 289.217,71.417L289.217,71.459C289.217,74.442 286.943,76.115 283.704,76.115C281.429,76.115 279.133,75.322 277.332,73.712Z" fill="rgb(206,201,197)" fillRule="nonzero" />
      <path d="M298.192,63.929L293.623,63.929L293.623,60.883L306.066,60.883L306.066,63.929L301.496,63.929L301.496,75.901L298.192,75.901L298.192,63.929Z" fill="rgb(206,201,197)" fillRule="nonzero" />
      <path d="M317.712,68.177C319.321,68.177 320.244,67.319 320.244,66.053L320.244,66.01C320.244,64.594 319.256,63.865 317.648,63.865L314.366,63.865L314.366,68.177L317.712,68.177ZM311.061,60.883L317.926,60.883C319.836,60.883 321.316,61.419 322.303,62.406C323.14,63.242 323.59,64.423 323.59,65.839L323.59,65.882C323.59,68.306 322.282,69.829 320.373,70.537L324.041,75.901L320.179,75.901L316.961,71.095L314.366,71.095L314.366,75.901L311.061,75.901L311.061,60.883Z" fill="rgb(206,201,197)" fillRule="nonzero" />
      <rect x="329.437" y="60.883" width="3.304" height="15.018" fill="rgb(206,201,197)" fillRule="nonzero" />
      <path d="M338.448,68.435L338.448,68.392C338.448,64.122 341.667,60.625 346.279,60.625C349.111,60.625 350.805,61.57 352.2,62.943L350.097,65.367C348.939,64.316 347.759,63.672 346.257,63.672C343.726,63.672 341.902,65.775 341.902,68.349L341.902,68.392C341.902,70.966 343.683,73.112 346.257,73.112C347.973,73.112 349.025,72.425 350.205,71.353L352.307,73.477C350.762,75.128 349.046,76.158 346.15,76.158C341.731,76.158 338.448,72.747 338.448,68.435Z" fill="rgb(206,201,197)" fillRule="nonzero" />
      <path d="M361.342,63.929L356.773,63.929L356.773,60.883L369.216,60.883L369.216,63.929L364.646,63.929L364.646,75.901L361.342,75.901L361.342,63.929Z" fill="rgb(206,201,197)" fillRule="nonzero" />
      <path d="M69.705,103L53.798,89.208L38.021,103L38.021,75.146L53.798,61.347L69.705,75.146L69.705,103Z" fill="rgb(251,176,64)" fillRule="nonzero" />
      <path d="M107.636,52.328L89.602,63.196L97.845,82.463L71.353,73.856L63.103,54.585L81.144,43.72L107.636,52.328Z" fill="rgb(165,38,113)" fillRule="nonzero" />
      <path d="M71.164,0.595L75.925,21.105L96.797,19.219L80.424,41.756L59.548,43.646L54.792,23.131L71.164,0.595Z" fill="rgb(144,176,62)" fillRule="nonzero" />
      <path d="M10.692,19.296L31.67,21.105L36.326,0.672L52.7,23.208L48.046,43.646L27.065,41.832L10.692,19.296Z" fill="rgb(227,75,38)" fillRule="nonzero" />
      <path d="M9.791,82.587L17.993,63.196L0,52.453L26.492,43.845L44.493,54.587L36.284,73.98L9.791,82.587Z" fill="rgb(0,90,155)" fillRule="nonzero" />
    </g>
</svg>;