import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Box, Container, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { AppLogo } from 'src/App/components/AppLogo';
import { RouterLink } from 'src/App/components/RouterLink';
import { paths } from 'src/App/paths';

const TOP_NAV_HEIGHT: number = 64;

const LayoutRoot = styled('div')(
  ({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
    backgroundImage: 'url("/assets/gradient-bg.svg")',
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    height: '100%'
  })
);

interface LayoutProps {
  children: ReactNode;
}

export const PublicLayout: FC<LayoutProps> = (props) => {
  const { children } = props;

  return (
    <LayoutRoot>
      <Box
        component="header"
        sx={{
          left: 0,
          position: 'fixed',
          right: 0,
          top: 20,
          zIndex: (theme) => theme.zIndex.appBar
        }}
      >
        <Container maxWidth="lg">
          <Stack
            direction="column"
            spacing={2}
            sx={{ height: TOP_NAV_HEIGHT }}
          >
            <Stack
              alignItems="center"
              component={RouterLink}
              direction="row"
              display="inline-flex"
              href={paths.public.index}
              spacing={1}
              sx={{ textDecoration: 'none' }}
            >
              <Box
                sx={{
                  display: 'inline-flex'
                }}
              >
                <AppLogo />
              </Box>
            </Stack>
          </Stack>
        </Container>
      </Box>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          flex: '1 1 auto'
        }}
      >
        <Container
          maxWidth="sm"
          sx={{
            py: {
              xs: '60px',
              md: '120px'
            }
          }}
        >
          {children}
        </Container>
      </Box>
    </LayoutRoot>
  );
};

PublicLayout.propTypes = {
  children: PropTypes.node
};
