export const tokens = {
  common: {
    languageChanged: 'common.languageChanged'
  },
  app: {
    appname: 'app.appname',
    login: 'app.login'
  },
  interface: {
    show: 'interface.show',
    save: 'interface.save',
    submit: 'interface.submit',
    hide: 'interface.hide',
    completeAndLock: 'interface.completeAndLock',
    formSubmitted: 'interface.formSubmitted',
    sendReminderEmail: 'interface.sendReminderEmail',
    sentTo: 'interface.sentTo',
    survey: 'interface.survey',
    worksheet: 'interface.worksheet',
    viewRecordHistory: 'interface.viewRecordHistory',
    viewUnSubmittedOnly: 'interface.viewUnSubmittedOnly',
  },
  labels: {
    amount: 'labels.amount',
    comments: 'labels.comments',
    createDate: 'labels.createDate',
    recordNo: 'labels.recordNo',
    ntpNo: 'labels.ntpNo',
    ntpTitle: 'labels.ntpTitle',
    remainingNtp: 'labels.ntpRemain',
    lineNo: 'labels.lineNo',
    lineComplete: 'labels.lineComplete',
    contractNo: 'labels.contractNo',
    projectName: 'labels.projectName',
    projectNumber: 'labels.projectNumber',
    periodsToAccrue: 'labels.periodsToAccrue',
    invoiceNumber: 'labels.invoiceNumber',
    invoiceAmount: 'labels.invoiceAmount',
    integrationComplete: 'labels.integrationComplete',
    lastApprovedPaymentPeriod: 'labels.lastApprovedPaymentPeriod',
    potentialPeriods: 'labels.potentialPeriods',
    servicesThroughDate: 'labels.servicesThroughDate',
    submitted: 'labels.submitted',
    submittedBy: 'labels.submittedBy',
    submittedOn: 'labels.submittedOn',
    instructions: 'labels.instructions',
    note: 'labels.note',
    simultaneousEditing: 'labels.simultaneousEditing',
  },
  nav: {
    home: 'nav.home',
    openntpsurvey: 'nav.openntpsurvey',
    accruals: 'nav.accruals',
    proserv: 'nav.proserv',
    jobs: 'nav.jobs',
    users: 'nav.users',
    queueData: 'nav.queueData'
  }
};
