import 'reflect-metadata'
import {Expose} from 'class-transformer';
import {ObjectId} from 'mongodb';

export class BaseEntity {
  constructor() {
    this.isActive = true;
    this.createdDate = new Date();
  }

  _id?: ObjectId;

  @Expose()
  createdDate: Date;

  @Expose()
  partitionKey: string;

  @Expose()
  rowKey: string;

  @Expose()
  isActive: boolean;
}