import React from 'react';
import {useAuth} from "src/App/hooks/use-auth";
import {usePopover} from "src/App/hooks/use-popover";
import {Avatar, Box, ButtonBase, SvgIcon} from "@mui/material";
import {getInitials} from "../../utils/get-initials";
import User01Icon from "@untitled-ui/icons-react/build/esm/User01";
import AccountPopover from "./AccountPopover";

const AccountButton: React.FC<IAccountButtonProps> = (props) => {
  const {account} = useAuth();
  const popover = usePopover<HTMLButtonElement>();

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={popover.handleOpen}
        ref={popover.anchorRef}
        sx={{
          alignItems: 'center',
          display: 'flex',
          borderWidth: 2,
          borderStyle: 'solid',
          borderColor: 'divider',
          height: 40,
          width: 40,
          borderRadius: '50%'
        }}
      >
        <Avatar
          sx={{height: 32, width: 32}}
          src={account?.photo}
        >
          {account?.name ? getInitials(account?.name) : (
            <SvgIcon fontSize="small">
              <User01Icon/>
            </SvgIcon>)}
        </Avatar>
      </Box>
      <AccountPopover
        anchorEl={popover.anchorRef.current}
        onClose={popover.handleClose}
        open={popover.open}
      />
    </>
  );
};

export default AccountButton;

interface IAccountButtonProps {
  children?: React.ReactNode
}