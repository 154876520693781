export const paths = {
  public: {
    index: '/',
    login: {
      index: '/login',
      checkToken: '/login/checkToken'
    }
  },
  authenticated: {
    index: '/',
    home: '/home',
    recordHistory: {
      index:'/recordHistory',
      detail:'/recordHistory/:recordNo'
    },
    app: {
      index: '/app',
      users: {
        index: '/app/users',
        new: '/app/users/new',
        detail: '/app/users/:userId'
      },
      jobs: {
        index: '/app/jobs',
        detail: '/app/jobs/:jobName',
        jobLog: '/app/jobs/:jobName/:jobRun'
      },
      queue: {
        index: '/app/queue',
      }
    },
    accruals: {
      index: '/accruals',
      details: '/accruals/:surveyId',
    },
    proserv: {
      index: '/proservaccruals',
      details: '/proservaccruals/:vendorRecordNo',
      accrualRecord: '/proservaccruals/:vendorRecordNo/:accrualRecordNo',
    },
    survey:{
      index: '/survey',
      detail: '/survey/:surveyId'
    },
    notifications: {
      index: '/notifications',
      notificationdetail: '/notifications/:notificationId'
    }
  },
};