import React, {useEffect} from 'react';
import Axios, {AxiosError, AxiosInstance} from 'axios';
import toast from 'react-hot-toast';
import {TModes} from 'api/application';
import {useAuth} from "src/App/hooks/use-auth";
import {apiSettings} from 'src/App/config';
import {useRouter} from 'src/App/hooks/use-router';
import {paths} from 'src/App/paths';

const NOT_AUTHENTICATED = 401;
const NOT_AUTHORIZED = 403;
export const ApiContext = React.createContext<IApiContext>({
  getClient:()=> {return undefined;},
  environment: 'DEVELOPMENT',
  apiVersion: ''
});
ApiContext.displayName = 'API Context';

export const ApiProvider: React.FC<{ children: any }> = (props) => {
  const auth = useAuth();
  const [apiClient, setApiClient] = React.useState<AxiosInstance | undefined>();
  const [environment, setEnvironment] = React.useState<TModes>('DEVELOPMENT');
  const [apiVersion, setApiVersion] = React.useState<string>('');

  const router = useRouter();
  useEffect(() => {
    console.group('API Provider');
    if (auth.jwt) {
      console.log(`Creating client with JWT: ${auth.jwt}`);
      const client = Axios.create();
      client.defaults.headers["sessionid"] = `${auth.jwt}`;
      client.defaults.baseURL = apiSettings.baseUrl;
      client.interceptors.response.use(async (res) => {
        if(res.data.message) toast.success(res.data.message);
        if(res.headers['api-version']) setApiVersion(res.headers['api-version']);
        if(res.headers['system-environment']) setEnvironment(res.headers['system-environment']);
        return res;
      }, async (error) => {
        // check if unauthorized, trigger re-auth
        console.dir(error);
        const message = getFriendlyErrorMessage(error);
        if(error.response.status == NOT_AUTHENTICATED){
          /*
          // todo reauthenticate session JWT against server
          console.log(auth.isSignedIn);
          if (auth.isSignedIn && auth.reAuthJwt && notAtLogin()) {
            await auth.reAuthJwt();
          } else */
          {
            toast.error(message);
            const newUrl = `${paths.public.login.index}?redirect=${window.location.pathname}`;
            router.replace(newUrl);
          }
        } else if(error.response.status == NOT_AUTHORIZED){
          toast.error(message);
          router.replace(paths.authenticated.home);
        }
        toast.error(message);
        return Promise.reject(error);
      })
      setApiClient(() => client);
    } else {
      console.log('Removing client');
      setApiClient(undefined);
    }
    console.groupEnd();
  }, [apiVersion, auth.jwt, environment]);

  const value: IApiContext = {
    getClient: () => apiClient,
    environment,
    apiVersion
  };

  return (
    <ApiContext.Provider value={value}>
      {props.children}
    </ApiContext.Provider>
  )

}

interface IApiContext {
  getClient(): AxiosInstance | undefined;
  environment: TModes;
  apiVersion: string;
}

export function getFriendlyErrorMessage(err: AxiosError<{ message: string; name: string; }>): string {
  return err?.response?.data?.message || err.message;
}

function notAtLogin(): boolean {
  return [paths.public.login, paths.public.index].indexOf(window.location.pathname) === -1;
}