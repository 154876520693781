
export const Roles = {
  DEFAULT_ROLE : "vendor",
  ADMIN_ROLE : 'admin'
}

export const enum Scopes {
  OpenNTPSurvey = 'openntpsurvey',
  Accruals = 'accruals',
  ProServAccruals = 'proservaccruals'
}