import React from 'react';
import {
  AppBarProps, Box, IconButton, Stack, SvgIcon, Theme, useMediaQuery
} from '@mui/material';
import {alpha} from '@mui/material/styles';
import AccountButton from './AccountButton';
import Menu01Icon from '@untitled-ui/icons-react/build/esm/Menu01';

const TOP_NAV_HEIGHT: number = 64;
const SIDE_NAV_WIDTH: number = 280;
export const Navbar: React.FC<PWMNavbarProps> = (props) => {
  const {onMobileNavOpen, ...other} = props;
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

  return (
    <Box
      component="header"
      sx={{
        backdropFilter: 'blur(6px)',
        backgroundColor: (theme) => alpha(theme.palette.background.default, 0.8),
        position: 'sticky',
        left: {
          lg: `${SIDE_NAV_WIDTH}px`
        },
        top: 0,
        width: {
          lg: `calc(100% - ${SIDE_NAV_WIDTH}px)`
        },
        zIndex: (theme) => theme.zIndex.appBar
      }}
      {...other}
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={2}
        sx={{
          minHeight: TOP_NAV_HEIGHT,
          px: 2
        }}
      >{!lgUp && (
        <Stack
          alignItems="center"
          direction="row"
          spacing={2}
        >
          <IconButton onClick={onMobileNavOpen}>
            <SvgIcon>
              <Menu01Icon />
            </SvgIcon>
          </IconButton>
        </Stack>
      )}
        <Stack
          alignItems="center"
          direction="row"
          spacing={2}
        >
          {/*<IconButton onClick={toggleSideBar}>
            <SvgIcon>
              <Menu01Icon />
            </SvgIcon>
          </IconButton>*/}
          <AccountButton />
        </Stack>
      </Stack>
    </Box>
  );
};

interface PWMNavbarProps extends AppBarProps {
  children?: React.ReactNode;
  onMobileNavOpen?: () => void;
}
