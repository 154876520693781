import type { FC, ReactElement } from 'react';
import SunIcon from '@untitled-ui/icons-react/build/esm/Sun';
import Moon01Icon from '@untitled-ui/icons-react/build/esm/Moon01';
import { Chip, PaletteMode, Stack, SvgIcon, Typography } from '@mui/material';
import {useSettings} from "src/App/hooks/use-settings";

interface Option {
  label: string;
  value: PaletteMode;
  icon: ReactElement;
}

const options: Option[] = [
  {
    label: 'Light',
    value: 'light',
    icon: (
      <SvgIcon fontSize="small">
        <SunIcon />
      </SvgIcon>
    )
  },
  {
    label: 'Dark',
    value: 'dark',
    icon: (
      <SvgIcon fontSize="small">
        <Moon01Icon />
      </SvgIcon>
    )
  }
];

interface OptionsColorSchemeProps {
}

export const ThemeSelector: FC<OptionsColorSchemeProps> = () => {
  const settings = useSettings();
  const updateTheme = (paletteMode: PaletteMode) => settings.handleUpdate({
      paletteMode
    });
  return (
    <Stack spacing={1}>
      <Typography
        color="text.secondary"
        variant="overline"
      >
        Theme
      </Typography>
      <Stack
        alignItems="center"
        direction="row"
        flexWrap="wrap"
        gap={2}
      >
        {options.map((option) => (
          <Chip
            icon={option.icon}
            key={option.value}
            label={option.label}
            onClick={() => updateTheme(option.value)}
            sx={{
              borderColor: 'transparent',
              borderRadius: 1.5,
              borderStyle: 'solid',
              borderWidth: 2,
              ...(option.value === settings.paletteMode && {
                borderColor: 'primary.main'
              })
            }}
          />
        ))}
      </Stack>
    </Stack>
  );
};
